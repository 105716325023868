import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import * as appActions from "../../core/app.store";
import * as config from '../../common/config'

import headCellsListCustomer from './head-cell-list-customer';
import DataTableSarang from '../../components/datatable-sarang';

import * as viVN from "../../language/vi-VN.json";
import { NotificationMessageType, sendReactGaEvent, getUserInfo } from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";

import * as customerManagementAction from "../../redux/store-sarang/customer/customer-management.store";
import * as taskCalendarManagementAction from "../../redux/store-sarang/task-calendar/task-calendar-management.store";
import * as departmentManagementAction from "../../redux/store-sarang/department/department-management.store";
import * as districtManagementAction from "../../redux/store/district-management/district.store"
import * as herdsmanManagementAction from "../../redux/store-sarang/herdsman/herdsman-management.store";
import * as zionManagementAction from "../../redux/store-sarang/zion/zion-management.store";
import * as groupManagementAction from "../../redux/store-sarang/group/group-management.store";
import * as positionManagementAction from "../../redux/store-sarang/position/position-management.store";

import ModalSubmitForm from '../../components/sarang-modals/modal-submit-form/modal-submit-form';
import FormAddEditCustomer from './components/form-add-edit-customer';
import FormAddDepartmentBatized from './components/form-update-department-baptized';
import Select from "react-select";
import { optionsCustomerStatus } from '../../common/constant';
import ModalConfirm from '../../components/sarang-modals/modal-confirm/modal-confirm';
import DepartmentHelper from '../list-department/helper/department-helper';
import FormAddEditTask from '../calendar-contact/add-edit-task/form-add-edit-task';
import { patternNumber } from '../../common/validatePhoneNumber';
import { useMediaQuery } from 'react-responsive';
import DataTablePagination from '../../components/datatable-sarang/datatable-pagination';
import FormSearchWrapperSarang, { FormSearchFunctionButtons } from '../../components/form-search-wrapper-sarang/form-search-wrapper-sarang';
import MobileHeaderSarang from '../../components/mobile-header/mobile-header-sarang';
import FormAddEditHerdsman from '../../pages/list-herdsman/components/form-add-edit-herdsman';
import FormViewCustomer from './components/form-view-customer';

const configLocal = {
    defaultPageSize: config.Configs.DefaultPageSize,
    sortExpression: "modifiedDate desc",
    orderBy: "modifiedDate",
    order: "desc",
}

export default function CustomerList() {
    const { register, handleSubmit, errors, setValue, control, getValues } = useForm({
        mode: "all",
        reValidateMode: "onBlur",
    });

    const isDesktopOrLaptop = useMediaQuery({
        query: "(min-width: 1024px)",
    });
    const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
    const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1023px)" });
    const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
    const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });

    const dispatch = useDispatch();
    const showLoading = (data) => dispatch(appActions.ShowLoading(data));
    const userInfo = getUserInfo();

    // -- Data to map with ids
    const [department, setDepartment] = useState([]);
    const [departmentHelping, setDepartmentHelping] = useState([]);
    const [departmentBTAll, setDepartmentBTAll] = useState([]);
    const [departmentBT, setDepartmentBT] = useState([]);
    const [district, setDistrict] = useState([]);
    const [herdsman, setHerdsman] = useState([]);
    const [optionsHerdsman, setOptionsHerdsman] = useState();
    const [optionsDistrict, setOptionsDistrict] = useState();
    const [searchData, setSearchData] = useState();
    const [openSearch, setOpenSearch] = useState(false);
    const [step, setStep] = useState("1");
    const [zion, setZion] = useState([]);
    const [position, setPosition] = useState([]);
    const [group, setGroup] = useState([]);
    const [customerDiary, setCustomerDiary] = useState();
    const year = new Date().getFullYear();
    const [herdsmanOwn, setHerdsmanOwn] = useState([]);
    
    const getLookupDepartment = async () => {
        try {
            const res = await departmentManagementAction.GetListNoPermiss(); //.GetLookupDepartment();
            if (res && res.content && res.content.items) {
                //setDepartment(DepartmentHelper.PrepareSortData(res.content.items.filter(x => (x.parentId == null || x.parentId === undefined) && x.id !== 53)));
                setDepartment(res.content.items.filter(x => (x.parentId == null || x.parentId === undefined) && x.id !== 53));
                setDepartmentHelping(DepartmentHelper.PrepareSortData(res.content.items.filter(x => x.id !== 53)));
                setDepartmentBTAll(res.content.items);
            }
        } catch (err) {
            throw err;
        }
    }
    
    const getLookupDistrict = async () => {
        try {
            const res = await districtManagementAction.GetLookupDistrict();
            if (res && res.content) {
                setDistrict(res.content);
                setOptionsDistrict(res.content.map(item => { return { label: item.name, value: item.id } }));
            }
        } catch (err) {
            throw err;
        }
    }
    
    const getLookupHerdsman = async () => {
        try {
            const res = await herdsmanManagementAction.GetLookupHerdsman();
            if (res && res.content) {
                setHerdsmanOwn(res.content);
                setOptionsHerdsman(res.content.map(item => { return { label: item.name, value: item.id } }));
            }

            const res1 = await herdsmanManagementAction.GetLookupHerdsman(false);
            if (res1 && res1.content) {
                setHerdsman(res1.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const [customer, setCustomer] = useState([]);
    const getLookupCustomer = async () => {
        try {
            const res = await customerManagementAction.GetLookupCustomer();
            if (res && res.content) {
                setCustomer(res.content);
            }
        } catch (err) {
            throw err;
        }
    }

    const getLookupZion = async () => {
        try {
            const res = await zionManagementAction.GetLookupZion();
            if (res && res.content) {
                setZion(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupPosition = async () => {
        try {
            const res = await positionManagementAction.GetLookupPosition();
            if (res && res.content) {
                setPosition(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const getLookupGroup = async () => {
        try {
            const res = await groupManagementAction.GetLookupGroup();
            if (res && res.content) {
                setGroup(res.content);
            }
        } catch (err) {
            throw err;
        }
    };

    const fetchData = async () => {
        showLoading(true);
        try {
            await Promise.allSettled([
                getLookupDepartment(),
                getLookupDistrict(),
                getLookupHerdsman(),
                getLookupCustomer(),
                getLookupGroup(),
                getLookupZion(),
                getLookupPosition()
            ]);
        } catch (err) {
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }
    
    // -- End Data to map with ids

    const [data, setData] = useState([])
    const [totalItemCount, setTotalItemCount] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(configLocal.defaultPageSize);
    const [order, setOrder] = useState(configLocal.order);
    const [orderBy, setOrderBy] = useState(configLocal.orderBy);

    useEffect(() => {
        getListCustomerManagement();
        fetchData();
    }, []);

    const getListCustomerManagement = async (pageIndex = 1, pageSize = configLocal.defaultPageSize, sortExpression = configLocal.sortExpression, searchData=undefined) => {
        showLoading(true);
        try {
            const res = await customerManagementAction.GetListCustomer(pageIndex, pageSize, sortExpression, searchData);
    
            if (res && res.content && res.content.items) {
                setData(res.content.items);
                setTotalItemCount(res.content.totalItemCount);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };

    const GetCustomerDiary = async (id) => {
        showLoading(true);
        sendReactGaEvent('Customer', 'GetCustomerDiary');
        try {
            const res = await customerManagementAction.GetCustomerDiary(id);

            if (res && res.content) {
                setCustomerDiary(res.content);
            }
        } catch (err) {
            if (err && err.errorType) {
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
            }
        } finally {
            showLoading(false);
        }
    };
    
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
        let sort = isAsc ? 'desc' : 'asc';
        let sortExpression = property + ' ' + sort;
        getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
    };

    const handleSortChange = (sortExpression) => {
        getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangePage = (newPage) => {
        setPage(newPage - 1);
        let sortExpression = orderBy + ' ' + order;
        getListCustomerManagement(newPage, rowsPerPage, sortExpression, searchData);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
        let sortExpression = orderBy + ' ' + order;
        getListCustomerManagement(1, event.target.value, sortExpression, searchData);
    };

    const handleSubmitAddEditCustomer = async (data) => {
        showLoading(true);

        try {
            let res = null;

            if( !data.id ) {
                res = await customerManagementAction.CreateCustomer(data);
            } else {
                res = await customerManagementAction.UpdateCustomer(data);
            }

            if (res && res.content) {
                if (!data.id)
                    ShowNotification(
                        viVN.Success["CreateSuccess"],
                        NotificationMessageType.Success
                    );
                else
                    ShowNotification(
                        viVN.Success["UpdateSuccess"],
                        NotificationMessageType.Success
                    );
                let sortExpression = orderBy + ' ' + order;
                getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
        } catch (err) {
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        } finally {
            showLoading(false);
        }
    }

    const handleSubmitAddEditSwat = async (data) => {
        showLoading(true);

        let formData = new FormData();
        //console.log(data);
        for (let key in data) {
            // false hoặc 0 vẫn append vào form
            if (data[key] !== undefined && data[key] !== null && data[key] !== '') formData.append(key, data[key]);
        }

        try {
            let res = null;

            res = await herdsmanManagementAction.CreateHerdsman(formData);
            if (res && res.content) {
                ShowNotification(
                    viVN.Success["CreateSuccess"],
                    NotificationMessageType.Success
                );
            }
            showLoading(false);
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }

    const handleSubmitAddEditTask = async (data) => {
        showLoading(true);

        try {
            let res = null;

            res = await taskCalendarManagementAction.CreateTask(data);
            if (res && res.content) {
                ShowNotification(
                    viVN.Success["CreateSuccess"],
                    NotificationMessageType.Success
                );
                //let sortExpression = orderBy + ' ' + order;
                //getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        } finally {
            showLoading(false);
        }
    }

    const handleUpdateDepartmentBaptized = async (data) => {
        showLoading(true);

        try {
            let res = null;

            res = await customerManagementAction.UpdateDepartmentBaptized(data);
            if (res && res.content) {
                ShowNotification(
                    viVN.Success["UpdateSuccess"],
                    NotificationMessageType.Success
                );
                let sortExpression = orderBy + ' ' + order;
                getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
                ShowNotification(
                    viVN.Errors[err.errorType],
                    NotificationMessageType.Error
                );
        } finally {
            showLoading(false);
        }
    }
     
    const buttonOpenAddEditRef = useRef();
    const buttonOpenAddSwatRef = useRef();
    const buttonOpenConfirmRef = useRef();
    const buttonOpenCreateCalendarRef = useRef();
    const [isOpenAddEditDialog, setOpenAddEditDialog] = useState(false);
    const [isOpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [isOpenActiveDialog, setOpenActiveDialog] = useState(false);
    const [isOpenDeactiveDialog, setOpenDeactiveDialog] = useState(false);
    const [isOpenChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
    const [isOpenCreateCalendarDialog, setOpenCreateCalendarDialog] = useState(false);
    const [isOpenViewCustomerDialog, setOpenViewCustomerDialog] = useState(false);
    const [isOpenConfirmCreateCalendarDialog, setOpenConfirmCreateCalendarDialog] = useState(false);
    const [isOpenAddSwatDialog, setOpenAddSwatDialog] = useState(false);
    const [isOpenSetBaptemDialog, setOpenSetBaptemDialog] = useState(false);
    const [swatItem, setSwatItem] = useState(null);
    const [customerId, setCustomerId] = useState(null);

    const [selectedItem, setSelectedItem] = useState(null);

    const openAddDialog = () => {
        setOpenAddEditDialog(true);
        buttonOpenAddEditRef.current.click();
    };

    const openSwatDialog = (item) => {
        setSwatItem({
            firstName: item?.fullName,
            gender: item?.gender,
            districtId: item?.districtId,
            districtName: item?.districtName,
            departmentId: item?.departmentId,
            departmentName: item?.departmentName,
            herdsmanType: 2,
            herdsmanTakeCareId: item?.herdsmanId1,
            herdsmanTakeCareName: item?.herdsmanName1,
            fromCustomerId: item?.id
        });
        setOpenAddSwatDialog(true);
        buttonOpenAddSwatRef.current.click();
    };

    const openEditDialog = (item) => {
        setOpenAddEditDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }

    const openCreateCalendarDialog = (item) => {
        setOpenCreateCalendarDialog(true);
        setSelectedItem(item);
        //buttonOpenCreateCalendarRef.current.click();
        buttonOpenAddEditRef.current.click();
    }

    const openBaptemDialog = (id) => {
        setOpenSetBaptemDialog(true);
        setCustomerId(id);
    }

    const openViewCustomerDialog = (item) => {
        setOpenViewCustomerDialog(true);
        setSelectedItem(item);
        buttonOpenAddEditRef.current.click();
    }

    const closeAddEditDialog = () => {
        setOpenAddEditDialog(false);
        setOpenCreateCalendarDialog(false);
        setOpenViewCustomerDialog(false);
        setOpenSetBaptemDialog(false);
        setSelectedItem(null);

        setOpenAddSwatDialog(false);
        setSwatItem(null);
    }

    const closeAddSwatDialog = () => {
        setOpenAddSwatDialog(false);
        setSwatItem(null);
    }

    const openConfirmDialog = (item) => {
        setSelectedItem(item);
        buttonOpenConfirmRef.current.click();
    }

    const closeConfirmDialog = () => {
        setOpenDeleteDialog(false);
        setOpenActiveDialog(false);
        setOpenDeactiveDialog(false);
        setOpenChangeStatusDialog(false);

        if (!isOpenConfirmCreateCalendarDialog) {
            setSelectedItem(null);
        }
            

        setOpenConfirmCreateCalendarDialog(false);
    }

    const handleConfirm = async () => {
        try {
            let res = null;

            if( isOpenDeleteDialog ) {
                res = await customerManagementAction.DeleteCustomer(selectedItem.id);
                if (res) {
                    ShowNotification(
                        viVN.Success["DeleteSuccess"],
                        NotificationMessageType.Success
                    );
                }
            } else if (isOpenActiveDialog) {
                res = await customerManagementAction.ActiveCustomer(selectedItem.id);
                if (res) {
                    ShowNotification(
                        viVN.Success["ActiveSuccess"],
                        NotificationMessageType.Success
                    );
                }
            } else if (isOpenDeactiveDialog) {
                res = await customerManagementAction.DeActiveCustomer(selectedItem.id);
                if (res) {
                    ShowNotification(
                        viVN.Success["DeActiveSuccess"],
                        NotificationMessageType.Success
                    );
                }
            } else if (isOpenChangeStatusDialog) {
                res = await customerManagementAction.ConfirmStepCustomer({ id: selectedItem.id, status: selectedItem.status });
                let status = selectedItem.status;
                if (res) {
                    ShowNotification(
                        viVN.Success["ChangeStatusSuccess"],
                        NotificationMessageType.Success
                    );
                    
                    if (res.content && res.content.length > 0 && selectedItem.status ==50) //Lấy list departmentId
                    {
                        setDepartmentBT(
                            res.content.map(item => {
                                return departmentBTAll.find(x => x.id == item);
                            })
                        );
                        openBaptemDialog(selectedItem.id);
                    }
                }
            } else if (isOpenConfirmCreateCalendarDialog) {
                sendReactGaEvent('Customer', 'Create calendar button click');
                openCreateCalendarDialog();
                //setOpenCreateCalendarDialog(true);
            }

            if (res) {
                let sortExpression = orderBy + ' ' + order;
                getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData);
            }

            showLoading(false);
            closeConfirmDialog();
        } catch (err) {
            showLoading(false);
            err && err.errorType &&
            ShowNotification(
                viVN.Errors[err.errorType],
                NotificationMessageType.Error
            );
        }
    }
    const onSubmit = (data) => {
        setSearchData(data);
        let sortExpression = orderBy + ' ' + order;
        setPage(0);
        getListCustomerManagement(1, rowsPerPage, sortExpression, data);
        if (isTabletOrMobile)
            setOpenSearch(false);
    }

    const renderFormSearch = () => {
        return <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="row">
                <div className="form-group col-md-3">
                    <div className="form-group">
                        <label htmlFor="input-fullName" className="col-form-label">Họ và tên</label>
                        <input
                            id="fullName"
                            className="form-control"
                            type="text"
                            name="fullName"
                            placeholder="Nhập họ tên"
                            ref={register()}
                        />
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <div className="form-group">
                        <label htmlFor="input-phoneNumber" className="col-form-label">Số điện thoại</label>
                        <input
                            id="phoneNumber"
                            className="form-control"
                            type="text"
                            name="phoneNumber"
                            placeholder="Số điện thoại"
                            ref={register()}
                        />
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <div className="form-group">
                        <label htmlFor="input-district">Chọn Quận/Huyện</label>
                        <Select
                            placeholder="Chọn Quận/huyện"
                            isClearable
                            {...register("districtId")}
                            onChange={(data) => {
                                setValue("districtId", data ? data.value : null);
                            }}
                            options={optionsDistrict}
                            noOptionsMessage={() => "Không tồn tại"}
                            // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                            menuPortalTarget={document.body}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        />
                    </div>
                </div>
                <div className="form-group col-md-3">
                    <label htmlFor="input-district">Trạng thái</label>
                    <Select
                        placeholder="Chọn Trạng thái"
                        isClearable
                        {...register("status")}
                        onChange={(data) => {
                            setValue("status", data ? data.value : null);
                        }}
                        options={optionsCustomerStatus}
                        noOptionsMessage={() => "Không tồn tại"}
                        // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            </div>
            <div className="row">
                <div className="form-group col-md-6">
                    <label htmlFor="input-age">Năm sinh</label>
                    <div className="row">
                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="fromYearOfBirth"
                                placeholder="Từ năm"
                                ref={register({ maxLength: 4, minLength: 4 })}
                                onChange={(e) =>
                                    setValue(
                                        "fromYearOfBirth",
                                        e.target.value.replace(patternNumber, "")
                                    )
                                }
                            />
                        </div>

                        <div className="col-6">
                            <input
                                className="form-control"
                                type="text"
                                name="toYearOfBirth"
                                placeholder="Đến năm"
                                ref={register({ maxLength: 4, minLength: 4 })}
                                onChange={(e) =>
                                    setValue(
                                        "toYearOfBirth",
                                        e.target.value.replace(patternNumber, "")
                                    )
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="form-group col-md-6">
                    <label htmlFor="input-department-general">Ace dẫn dắt</label>
                    <Select
                        isClearable
                        {...register("herdsmanId")}
                        placeholder="Chọn ACE dẫn dắt"
                        onChange={(data) => {
                            setValue("herdsmanId", data ? data.value : null);
                        }}
                        options={optionsHerdsman}
                        noOptionsMessage={() => "Không tồn tại"}
                        menuPortalTarget={document.body}
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    />
                </div>
            </div>
            <FormSearchFunctionButtons>
                <div className="row">
                    <div className="col-sm-12 pl-0">
                        <p className="text-center">
                            <button type="submit" className="btn btn-space btn-primary">Tìm kiếm</button>
                            <button className="btn btn-space btn-secondary"
                                onClick={(e) => {
                                    e.preventDefault();
                                }}>Xóa lọc</button>
                            <button
                                className="btn btn-space btn-warning"
                                onClick={(e) => {
                                    e.preventDefault();
                                    openAddDialog();
                                }}
                            >Thêm mới</button>
                        </p>
                    </div>
                </div>
            </FormSearchFunctionButtons>
        </form>
    }

    const renderListItemForMobile = () => {
        return <>
            <div className="accrodion-regular">
                <MobileHeaderSarang
                    headCell={headCellsListCustomer}
                    handleSortChange={(sortExpression) => getListCustomerManagement(page + 1, rowsPerPage, sortExpression, searchData)}
                    setOrder={setOrder}
                    setOrderBy={setOrderBy }
                />
                <div id="accordion">
                    
                    {
                        data.length > 0 ?
                            data.map((item, index) => (
                                <div className="card" key={index}>
                                    <div className="d-flex align-items-center" id={`heading-${item.id}`}>
                                        <div className="list-group-item list-group-item-action flex-column align-items-start collapsed" style={{ border: "none" }}
                                            data-toggle="collapse" data-target={`#collapse-${item.id}`} aria-expanded="false" aria-controls={`collapse-${item.id}`}>
                                            <div className='row'>
                                                <div className='col-6'>
                                                    <div className='mt-0'><b>Họ tên:</b></div>
                                                    <span onClick={() => {
                                                        GetCustomerDiary(item.id);
                                                        openViewCustomerDialog(item);
                                                    }}>{item.gender ? <li className="fas fa-male text-primary"></li> : <li className="fas fa-female text-secondary"></li>}
                                                        {item?.status >= 70 ?
                                                            <img title={item.statusName} style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/fb_tick.png")}
                                                                alt="Tick"
                                                            /> : <></>} {item.fullName}</span>
                                                    <div className='mt-0'>
                                                        <b><li className="fas  fas fa-birthday-cake text-primary">&nbsp;</li></b>
                                                        <span>
                                                            {item.yearOfBirth}
                                                        </span>
                                                    </div>

                                                    {
                                                        item.needHelp ? 
                                                            <div className="mt-0"><b><li className=" fas fa-hands-helping text-primary">Cần trợ giúp</li></b>&nbsp;
                                                                <span>
                                                                    <img title="Cần trợ giúp"
                                                                        src={require("../../assets/icon/tick.png")}
                                                                        alt="Tick"
                                                                    />
                                                                </span>
                                                            </div> : <></>
                                                    }
                                                    
                                                    <div className="mt-0"><b>Đang kích hoạt? </b>
                                                        <span>
                                                            {item.isActived ? (
                                                                <img title="Đang kích hoạt" style={{ cursor: 'pointer' }}
                                                                    src={require("../../assets/icon/tick.png")}
                                                                    alt="Tick" onClick={() => {
                                                                        setOpenDeactiveDialog(true);
                                                                        openConfirmDialog(item);
                                                                    }}
                                                                />
                                                            ) : (
                                                                <img title="Ngưng kích hoạt" style={{ cursor: 'pointer' }}
                                                                    src={require("../../assets/icon/cancel.png")}
                                                                    alt="Cancel" onClick={() => {
                                                                        setOpenActiveDialog(true);
                                                                        openConfirmDialog(item);
                                                                    }}
                                                                />
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className='col-6'>
                                                    {item.phoneNumber !== "" ? 
                                                        <div className='mt-2'><b><li className=" fas fa-phone text-primary">&nbsp;</li></b>
                                                            <span>
                                                                {item.phoneNumber}
                                                            </span>
                                                        </div>: <></>}
                                                    
                                                    <div className='mt-2'><b>Ace dẫn dắt: </b>
                                                        {item.herdsmanName1 && <><span>{item.herdsmanName1}
                                                            {item.needHelp && <> (<a href={`tel:${item.herdsmanPhone1}`}>{item.herdsmanPhone1}</a>)</>}
                                                        </span><br /></>}
                                                        {item.herdsmanName2 && <><span>{item.herdsmanName2}
                                                        </span><br /></>}
                                                        {item.herdsmanName3 && <span>{item.herdsmanName3}
                                                        </span>}
                                                    </div>
                                                    <div className='mt-0'>
                                                        {item.buoc1_Actual ? (
                                                            <img title="Đã qua bước 1"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : (item.allowEdit?
                                                            <img title="Chưa qua bước 1, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel" onClick={() => {
                                                                    setStep("1");
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, status: 10 });
                                                                }}
                                                                /> : <img title="Chưa qua bước 1"
                                                                    src={require("../../assets/icon/cancel.png")}
                                                                    alt="Cancel"
                                                                />
                                                        )}&nbsp;
                                                        {item.buoc2_Actual ? (
                                                            <img title="Đã qua bước 2"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : (item.allowEdit && year - item.yearOfBirth > 13 && item.customerType!=1 ?
                                                                <img title={`Chưa qua bước 2, Bấm để xác nhận`} style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel" onClick={() => {
                                                                    setStep("2");
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, status: 20 });
                                                                }}
                                                                /> : <img title={item.customerType == 1 ? "La-xa-rơ không tích bước 2" : year - item.yearOfBirth > 13 ? "Chưa qua bước 2" : "Ta-lâng từ 13 tuổi trở xuống không được tick bước 2"}
                                                                    src={require("../../assets/icon/cancel.png")}
                                                                    alt="Cancel"
                                                                />
                                                        )}
                                                        &nbsp;
                                                        {item.buoc3_Actual ? (
                                                            <img title="Đã qua bước 3"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : (item.allowEdit && year - item.yearOfBirth > 13 && item.customerType != 1 ?
                                                            <img title="Chưa qua bước 3, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel" onClick={() => {
                                                                    setStep("3");
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, status: 30 });
                                                                }}
                                                                /> : <img title={item.customerType == 1 ? "La-xa-rơ không tích bước 3" : year - item.yearOfBirth > 13 ? "Chưa qua bước 3" : "Ta-lâng từ 13 tuổi trở xuống không được tick bước 3"}
                                                                    src={require("../../assets/icon/cancel.png")}
                                                                    alt="Cancel"
                                                                />
                                                        )}
                                                        &nbsp;
                                                        {item.buoc4_Actual ? (
                                                            <img title="Đã qua bước 4"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : (item.allowChangeStatus && year - item.yearOfBirth > 13 && item.customerType != 1 ?
                                                            <img title="Chưa qua bước 4, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel" onClick={() => {
                                                                    setStep("4");
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, status: 40 });
                                                                }}
                                                                /> : <img title={item.customerType == 1 ? "La-xa-rơ không tích bước 4" : year - item.yearOfBirth > 13 ? "Chưa qua bước 4" : "Ta-lâng từ 13 tuổi trở xuống không được tick bước 4"}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel"
                                                            />
                                                        )}
                                                        &nbsp;
                                                        {item.buoc5_Actual ? (
                                                            <img title="Đã qua bước 5"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : (item.allowChangeStatus && item.customerType != 1 ?
                                                            <img title="Chưa qua bước 5, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel" onClick={() => {
                                                                    setStep("5");
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, status: 50 });
                                                                }}
                                                            /> : <img title={item.customerType == 1 ? "La-xa-rơ không tích bước 5" : "Chưa qua bước 5"}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel"
                                                            />) }
                                                        &nbsp;
                                                        {item.buoc6_Actual ? (
                                                            <img title="Đã qua bước 6"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : (item.allowChangeStatus && item.buoc5_Actual ?
                                                            <img title="Chưa qua bước 6, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel" onClick={() => {
                                                                    setStep("6");
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, status: 60 });
                                                                }}
                                                            /> : <img title="Chưa qua bước 6"
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel"
                                                            />
                                                        )}
                                                        &nbsp;
                                                        {item.buoc7_Actual ? (
                                                            <img title="Đã qua bước 7"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) ?
                                                            (item.allowChangeStatus && item.buoc6_Actual ?
                                                            <img title="Chưa qua bước 7, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel" onClick={() => {
                                                                    setStep("7");
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, status: 70 });
                                                                }}
                                                            /> : <img title="Chưa qua bước 7"
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel"
                                                                    />)
                                                            :
                                                            <img title="Chưa qua bước 7"
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel"
                                                            />
                                                        )}
                                                        &nbsp;
                                                        {item.buoc8_Actual ? (
                                                            <img title="Đã qua bước 8"
                                                                src={require("../../assets/icon/tick.png")}
                                                                alt="Tick"
                                                            />
                                                        ) : (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) ?
                                                            (item.allowChangeStatus && item.buoc7_Actual ?
                                                            <img title="Chưa qua bước 8, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel" onClick={() => {
                                                                    setStep("8");
                                                                    setOpenChangeStatusDialog(true);
                                                                    openConfirmDialog({ ...item, status: 80 });
                                                                }}
                                                            /> : <img title="Chưa qua bước 8"
                                                                src={require("../../assets/icon/cancel.png")}
                                                                alt="Cancel"
                                                                    />) :
                                                                <img title="Chưa qua bước 8"
                                                                    src={require("../../assets/icon/cancel.png")}
                                                                    alt="Cancel"
                                                                />
                                                        )}
                                                        {/*
                                                        <Select
                                                            value={item?.status ? { label: item.statusName, value: item.status } : null}

                                                            onChange={(data) => {
                                                                setOpenChangeStatusDialog(true);
                                                                openConfirmDialog({ ...item, status: data.value, statusName: data.label });
                                                            }}
                                                            options={optionsCustomerStatus}
                                                            noOptionsMessage={() => "Không tồn tại"}
                                                            // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                            menuPortalTarget={document.body}
                                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                                        />
                                                        */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <i className="fas fa-angle-down p-2"></i>
                                    </div>
                                    <div id={`collapse-${item.id}`} className="collapse" aria-labelledby={`heading-${item.id}`} data-parent="#accordion">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className='mt-0'>
                                                        <b>Độ tuổi: </b>
                                                        <span>
                                                            {item.departmentName}
                                                        </span>
                                                    </div>
                                                    <div className='mt-0'><b>Quận/huyện: </b>
                                                        <span>{item.districtName}</span>
                                                    </div>
                                                    <div className='mt-0'><b>Nghề nghiệp: </b>
                                                        <span>{item.career}</span>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className='mt-0'><b>Ghi chú: </b>
                                                        <span>{item.description} - <b><i>{item.needHelp ? "Liên kết giúp đỡ - (" + item.herdsmanHelpingName1 + " - " + item.herdsmanHelpingPhone1 + " - " + item?.departmentHelpingName + ")" : ""}</i></b></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex mt-2'>
                                                {
                                                    item?.status && item?.status >= 50 && item?.isActived && !item?.referenceToHerdsmanId ?
                                                        <button className="d-inline-block btn btn-xs btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                            title="Tạo tên thánh đồ"
                                                            onClick={() => {
                                                                openSwatDialog(item);
                                                            }}
                                                        >
                                                            <i className="fas fa-fw fa-user-secret"></i>
                                                        </button> : <></>
                                                }
                                                <button className="d-inline-block btn btn-xs btn-outline-light custom-button-table create-calendar-customer" data-toggle="tooltip" data-placement="top"
                                                    title="Xếp lịch"
                                                    onClick={() => {
                                                        //setOpenConfirmCreateCalendarDialog(true);
                                                        //openConfirmDialog(item);
                                                        openCreateCalendarDialog(item);
                                                    }}
                                                >
                                                    <i className="far fa-calendar-alt"></i>
                                                </button>
                                                {
                                                    item.allowEdit ? <><button className="d-inline-block btn btn-xs btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                        title="Sửa"
                                                        onClick={() => { openEditDialog(item) }}
                                                    ><i className="far fa-edit"></i>
                                                    </button>
                                                        <button className="d-inline-block btn btn-xs btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                            title="Xóa"
                                                            onClick={() => {
                                                                setOpenDeleteDialog(true);
                                                                openConfirmDialog(item);
                                                            }}
                                                        >
                                                            <i className="far fa-trash-alt"></i>
                                                        </button></> : <></>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )) : <div className="card">Chưa có dữ liệu</div>
                    }
                </div>
                {
                    totalItemCount > 0 ?
                        <DataTablePagination
                            totalItemCount={totalItemCount}
                            rowsPerPage={rowsPerPage}
                            pageIndex={page + 1}
                            handleChangePage={handleChangePage}
                        /> :
                        <></>
                }
            </div>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
        </>
    }

    const renderTableForDesktop = () => {
        return <>
            <FormSearchWrapperSarang
                isTabletOrMobile={isTabletOrMobile} openSearch={openSearch} setOpenSearch={setOpenSearch} openAddDialog={openAddDialog}
            >
                {renderFormSearch()}
            </FormSearchWrapperSarang>
            <DataTableSarang
                // button functions
                rowsPerPage={rowsPerPage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                // head cells
                headCells={headCellsListCustomer}
                handleRequestSort={handleRequestSort}
                order={order}
                orderBy={orderBy}
                // pagination
                totalItemCount={totalItemCount}
                setRowsPerPage={setRowsPerPage}
                pageIndex={page}
                handleChangePage={handleChangePage}
            >
                <tbody>
                    {
                        data.length > 0 ?
                            data.map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    <td>
                                        <span style={{ cursor: 'pointer' }} onClick={() => {
                                            GetCustomerDiary(row.id);
                                            openViewCustomerDialog(row);
                                        } }>
                                            {row["gender"] ? <li className="fas fa-male text-primary"></li> : <li className="fas fa-female text-secondary"></li>}
                                            {row?.status >= 70 ?
                                                <img title={row.statusName}
                                                    src={require("../../assets/icon/fb_tick.png")}
                                                    alt="Tick"
                                                /> : <></>} {row.fullName} {row.customerType===1 ? "(La-xa-rơ)" : "" } {row.needHelp ? (<b><li title="Cần trợ giúp" className="fas fa-hands-helping text-danger"></li></b>) : ""}</span></td>
                                    <td>
                                        <span>
                                            {row.isActived ? (
                                                <img title="Đang kích hoạt" style={{ cursor: 'pointer' }}
                                                    src={require("../../assets/icon/tick.png")}
                                                    alt="Tick" onClick={() => {
                                                        setOpenDeactiveDialog(true);
                                                        openConfirmDialog(row);
                                                    }}
                                                />
                                            ) : (
                                                <img title="Ngưng kích hoạt" style={{ cursor: 'pointer' }}
                                                    src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel" onClick={() => {
                                                        setOpenActiveDialog(true);
                                                        openConfirmDialog(row);
                                                    }}
                                                />
                                            )}
                                        </span>
                                    </td>
                                    <td><span>{row.yearOfBirth}</span></td>
                                    {/*<td><span>{row.gender===0 ? "Nữ" : "Nam"}</span></td>*/}
                                    <td><span>{row.departmentName}</span></td>
                                    <td><span>{row.districtName}</span></td>
                                    <td>
                                        <span>{row.phoneNumber}</span>
                                    </td>
                                    <td>
                                        <span>{row.herdsmanName1} {row.needHelp && <> (<a href={`tel:${row.herdsmanPhone1}`}>{row.herdsmanPhone1}</a>)</>}
                                        </span><br />
                                        <span>{row.herdsmanName2}
                                        </span><br />
                                        <span>{row.herdsmanName3}
                                        </span>
                                    </td>
                                    <td>
                                        <span>{row.statusName}
                                            {/*
                                                <Select
                                                value={row?.status ? { label: row.statusName, value: row.status } : null}
                                                placeholder="Chọn Trạng thái"
                                                onChange={(data) => {
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: data.value, statusName: data.label });
                                                }}
                                                options={optionsCustomerStatus}
                                                noOptionsMessage={() => "Không tồn tại"}
                                                // style để dropdown đè absolute lên cả body thay vì bị giới hạn chỉ trong table
                                                menuPortalTarget={document.body}
                                                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            />
                                                */ }
                                            
                                        </span>
                                    </td>
                                    <td><span>
                                        {row.buoc1_Actual ? (
                                            <img title="Đã qua bước 1"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            />
                                        ) : (row.allowEdit ? 
                                            <img title="Chưa qua bước 1, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel" onClick={() => {
                                                        setStep("1");
                                                        setOpenChangeStatusDialog(true);
                                                        openConfirmDialog({ ...row, status: 10 });
                                                }}
                                                /> :
                                                <img title="Chưa qua bước 1"
                                                    src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel"/>
                                        )}&nbsp;
                                        {row.buoc2_Actual ? (
                                            <img title="Đã qua bước 2"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            />
                                        ) : (row.allowEdit && year - row.yearOfBirth > 13 && row.customerType != 1 ? 
                                            <img title="Chưa qua bước 2, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel" onClick={() => {
                                                    setStep("2");
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: 20 });
                                                }}
                                                /> :
                                                <img title={row.customerType == 1 ? "La-xa-rơ không tích bước 2" : year - row.yearOfBirth > 13 ? "Chưa qua bước 2" : "Ta-lâng từ 13 tuổi trở xuống không được tick bước 2"}
                                                    src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel" />
                                        )}
                                    &nbsp;
                                        {row.buoc3_Actual ? (
                                            <img title="Đã qua bước 3"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            />
                                        ) : (row.allowEdit && year - row.yearOfBirth > 13 && row.customerType != 1 ? 
                                            <img title="Chưa qua bước 3, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" onClick={() => {
                                                    setStep("3");
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: 30 });
                                                }}
                                                /> :
                                                <img title={row.customerType == 1 ? "La-xa-rơ không tích bước 3" : year - row.yearOfBirth > 13 ? "Chưa qua bước 3" : "Ta-lâng từ 13 tuổi trở xuống không được tick bước 3"}
                                                    src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel" />
                                        )}
                                    &nbsp;
                                        {row.buoc4_Actual ? (
                                            <img title="Đã qua bước 4"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            />
                                        ) : (row.allowChangeStatus && year - row.yearOfBirth > 13 && row.customerType != 1 ?
                                            <img title="Chưa qua bước 4, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" onClick={() => {
                                                    setStep("4");
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: 40 });
                                                }}
                                                /> : <img title={row.customerType == 1 ? "La-xa-rơ không tích bước 4" : year - row.yearOfBirth > 13 ? "Chưa qua bước 4" : "Ta-lâng từ 13 tuổi trở xuống không được tick bước 4"}
                                                    src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel"
                                                />
                                        )}
                                    &nbsp;
                                        {row.buoc5_Actual ? (
                                            <img title="Đã qua bước 5"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            />
                                        ) : (row.allowChangeStatus && row.customerType != 1 ?
                                            <img title="Chưa qua bước 5, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" onClick={() => {
                                                    setStep("5");
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: 50 });
                                                }}
                                            /> : <img title={row.customerType == 1 ? "La-xa-rơ không tích bước 5" : "Chưa qua bước 5"}
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                            />)}
                                    &nbsp;
                                        {row.buoc6_Actual ? (
                                            <img title="Đã qua bước 6"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            />
                                        ) : (row.allowChangeStatus && row.buoc5_Actual ?
                                            <img title="Chưa qua bước 6, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" onClick={() => {
                                                    setStep("6");
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: 60 });
                                                }}
                                            /> : <img title="Chưa qua bước 6"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                            />
                                        )}
                                    &nbsp;
                                        {row.buoc7_Actual ? (
                                            <img title="Đã qua bước 7"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            />
                                        ) : (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) ?
                                            (row.allowChangeStatus && row.buoc6_Actual ?
                                            <img title="Chưa qua bước 7, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" onClick={() => {
                                                    setStep("7");
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: 70 });
                                                }}
                                            /> : <img title="Chưa qua bước 7"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                                    />)
                                                : <img title="Chỉ có CS mới có quyền tick bước 7"
                                                    src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel"
                                                />
                                        )}
                                    &nbsp;
                                        {row.buoc8_Actual ? (
                                            <img title="Đã qua bước 8"
                                                src={require("../../assets/icon/tick.png")}
                                                alt="Tick"
                                            />
                                        ) : (userInfo && userInfo.userRoles && (userInfo.userRoles.includes("CHAPSU") || userInfo.userRoles.includes("ADMIN")) ?
                                            (row.allowChangeStatus && row.buoc7_Actual ?
                                            <img title="Chưa qua bước 8, Bấm để xác nhận" style={{ cursor: 'pointer' }}
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel" onClick={() => {
                                                    setStep("8");
                                                    setOpenChangeStatusDialog(true);
                                                    openConfirmDialog({ ...row, status: 80 });
                                                }}
                                            /> : <img title="Chưa qua bước 8"
                                                src={require("../../assets/icon/cancel.png")}
                                                alt="Cancel"
                                                    />)
                                                : <img title="Chỉ có CS mới có quyền tick bước 8"
                                                    src={require("../../assets/icon/cancel.png")}
                                                    alt="Cancel"
                                                />
                                        )}
                                    </span></td>
                                    <td><span>{row.career}</span></td>
                                    <td>
                                        <span>{row.description} - <b><i>{row.needHelp ? "Liên kết giúp đỡ - (" + row.herdsmanHelpingName1 + " - " + row.herdsmanHelpingPhone1 + " - " + row?.departmentHelpingName + ")" : ""}</i></b></span>
                                    </td>
                                    <td>
                                        <div className='d-flex'>
                                            {
                                                row?.status && row?.status >= 50 && row?.isActived && !row?.referenceToHerdsmanId ?
                                                    <button className="d-inline-block btn btn-xs btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                        title="Tạo tên thánh đồ"
                                                        onClick={() => {
                                                            openSwatDialog(row);
                                                        }}
                                                    >
                                                        <i className="fas fa-fw fa-user-secret"></i>
                                                    </button> : <></>
                                            }
                                            <button className="d-inline-block btn btn-xs btn-outline-light custom-button-table create-calendar-customer" data-toggle="tooltip" data-placement="top"
                                                title="Xếp lịch"
                                                onClick={() => {
                                                    setOpenConfirmCreateCalendarDialog(true);
                                                    openConfirmDialog(row);
                                                }}
                                            >
                                                <i className="far fa-calendar-alt"></i>
                                            </button>
                                            {
                                                row.allowEdit ? <><button className="d-inline-block btn btn-xs btn-outline-light custom-button-table edit" data-toggle="tooltip" data-placement="top"
                                                    title="Sửa"
                                                    onClick={() => { openEditDialog(row) }}
                                                ><i className="far fa-edit"></i>
                                                </button>
                                                    <button className="d-inline-block btn btn-xs btn-outline-light custom-button-table delete" data-toggle="tooltip" data-placement="top"
                                                        title="Xóa"
                                                        onClick={() => {
                                                            setOpenDeleteDialog(true);
                                                            openConfirmDialog(row);
                                                        }}
                                                    >
                                                        <i className="far fa-trash-alt"></i>
                                                    </button></> : <></>
                                            }
                                            
                                        </div>
                                    </td>
                                </tr>
                            )) :
                            <tr><td colSpan={10}>Chưa có dữ liệu</td></tr>
                    }
                </tbody>
            </DataTableSarang>
        </>
    }

    return (
        <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                {
                    isDesktopOrLaptop ? renderTableForDesktop() : <></>
                }

                {
                    isTabletOrMobile ? renderListItemForMobile() : <></>
                }
            </div>
            <button ref={buttonOpenAddEditRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenAddSwatRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenCreateCalendarRef} type="button" className="d-none" data-toggle="modal" data-target="#modalSubmitForm">
                Launch modal add edit
            </button>
            <button ref={buttonOpenConfirmRef} type="button" className="d-none" data-toggle="modal" data-target="#modalConfirm">
                Launch modal confirm
            </button>

            <ModalSubmitForm
                title={isOpenAddSwatDialog ? "Thêm mới swat" : isOpenViewCustomerDialog ? "Xem thông tin" : !selectedItem ? "Thêm mới ta-lâng" : isOpenCreateCalendarDialog ? "Lập lịch chia sẻ" : isOpenSetBaptemDialog ? "Chọn đội" : "Chỉnh sửa ta-lâng"}
                open={isOpenAddEditDialog || isOpenCreateCalendarDialog || isOpenAddSwatDialog || isOpenViewCustomerDialog || isOpenSetBaptemDialog}
                onClose={closeAddEditDialog}
            >
                {
                    isOpenAddSwatDialog ? 
                        <FormAddEditHerdsman
                            group={group}
                            department={DepartmentHelper.RenderOptions(department)}
                            herdsman={optionsHerdsman}
                            zion={zion}
                            district={district}
                            position={position}
                            // ===
                            updateItem={swatItem}
                            onSubmitAddEdit={handleSubmitAddEditSwat}
                        /> :
                        isOpenViewCustomerDialog ?
                            <FormViewCustomer
                                department={department}
                                departmentHelping={DepartmentHelper.RenderOptions(departmentHelping)}
                                district={district}
                                herdsman={herdsman}
                                customerDiary={customerDiary}
                                // ===
                                updateItem={selectedItem}
                                onSubmitAddEdit={handleSubmitAddEditCustomer}
                            /> : 
                            isOpenSetBaptemDialog ?
                                <FormAddDepartmentBatized
                                    department={departmentBT}
                                    onSubmitAddEdit={handleUpdateDepartmentBaptized}
                                    customerId={customerId}
                                /> :
                    !isOpenCreateCalendarDialog ? 
                    <FormAddEditCustomer
                         department={department}
                        //department={DepartmentHelper.RenderOptions(department)}
                        departmentHelping={DepartmentHelper.RenderOptions(departmentHelping)}
                        district={district}
                        herdsman={herdsman}
                        // ===
                        updateItem={selectedItem}
                        onSubmitAddEdit={handleSubmitAddEditCustomer}
                    /> : 
                    <FormAddEditTask
                        customer={customer}
                        herdsman={herdsman}
                        herdsmanOwn={herdsmanOwn}
                        // ===
                        customerForCreateTask={selectedItem}
                        handleSubmitAddEditTask={handleSubmitAddEditTask}
                    />
                }
                
            </ModalSubmitForm>



            <ModalConfirm
                title={"Xác nhận"}
                prompt={
                    isOpenDeleteDialog ? "Bạn có chắc chắn muốn xóa bản ghi này không?":
                    isOpenActiveDialog ? "Bạn có chắc chắn muốn mở khóa bản ghi này không?":
                    isOpenDeactiveDialog ? "Bạn có chắc chắn muốn khóa bản ghi này không?": 
                    isOpenChangeStatusDialog ? `Bạn có chắc chắn muốn thay đổi trạng thái cho chiên <b>"${selectedItem?.fullName}"</b> thành <b> Bước "${step}"</b>?` :
                    isOpenConfirmCreateCalendarDialog ? `Bạn có chắc chắn muốn tạo lịch cho chiên <b>"${selectedItem?.fullName}"</b>?` : ""
                }
                open={isOpenDeleteDialog || isOpenActiveDialog || isOpenDeactiveDialog || isOpenChangeStatusDialog || isOpenConfirmCreateCalendarDialog}
                onClose={closeConfirmDialog}
                onConfirm={handleConfirm}
            />
        </div>
    )
}