import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();
export const GetMyListHerdsmanRank = (festivalCogId =undefined, week=undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    week && params.append("week", week);
    return service.get(ApiUrl.GetMyListHerdsmanRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetAllListHerdsmanRank = (festivalCogId, week = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    week && params.append("week", week);
    return service.get(ApiUrl.GetAllListHerdsmanRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetMyListDepartmentRank = (festivalCogId = undefined, week = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    week && params.append("week", week);
    return service.get(ApiUrl.GetMyListDepartmentRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetAllListDepartmentRank = (festivalCogId = undefined, week = undefined) => {
    const params = new URLSearchParams();
    festivalCogId && params.append("festivalCogId", festivalCogId);
    week && params.append("week", week);
    return service.get(ApiUrl.GetAllListDepartmentRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetHerdsmanExaminationReport = (herdsmanId = undefined, festivalCogId = undefined, week = undefined) => {
    const params = new URLSearchParams();
    herdsmanId && params.append("herdsmanId", herdsmanId);
    festivalCogId && params.append("festivalCogId", festivalCogId);
    week && params.append("week", week);
    return service.get(ApiUrl.GetHerdsmanExaminationReport, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDepartmentExaminationReport = (departmentId = undefined, festivalCogId = undefined, week = undefined) => {
    const params = new URLSearchParams();
    departmentId && params.append("departmentId", departmentId);
    festivalCogId && params.append("festivalCogId", festivalCogId);
    week && params.append("week", week);
    return service.get(ApiUrl.GetDepartmentExaminationReport, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetCurrentWeek = () => {
    return service.get(ApiUrl.GetCurrentWeek).then((res) => { return res }).catch((err) => { throw err });
}
export const GetExactlyCurrentWeek = () => {
    return service.get(ApiUrl.GetExactlyCurrentWeek).then((res) => { return res }).catch((err) => { throw err });
}
export const GetMyExaminationRank = (week = undefined, month = undefined, year = undefined) => {
    const params = new URLSearchParams();
    month && params.append("month", month);
    year && params.append("year", year);
    week && params.append("week", week);
    return service.get(ApiUrl.GetMyExaminationRank, params).then((res) => { return res }).catch((err) => { throw err });
}
export const GetDepartmentExam = (month = undefined, year = undefined) => {
    const params = new URLSearchParams();
    month && params.append("month", month);
    year && params.append("year", year);
    return service.get(ApiUrl.GetDepartmentExam, params).then((res) => { return res }).catch((err) => { throw err });
}